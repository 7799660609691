import React from 'react';
import { ranges } from '../data/range-data';
import { DEXFDataType } from '../utils/types';

export type RangeSelectorProps = {
  range: string;
  dataType: DEXFDataType;
  onRangeSelected: (range: string) => void;
  onDataTypeSelected: (dataType: DEXFDataType) => void;
};

export const Header = ({
  range,
  dataType,
  onRangeSelected,
  onDataTypeSelected,
}: RangeSelectorProps) => {
  const rangeList =
    range === ''
      ? ['Select range', ...ranges.map((range) => range.name)]
      : ranges.map((range) => range.name);
  rangeList.push('Custom ART/SPR');

  const dataOptions = Object.values(DEXFDataType);

  const onSelectRange = (event: any) => {
    onRangeSelected(event.target.value);
  };

  const onSelectOption = (event: any) => {
    onDataTypeSelected(event.target.value);
  };

  return (
    <section className="App-header has-background-info has-text-white">
      <h1 className="title has-text-white">Welcome to ÖVERBLICKA</h1>
      <h2 className="subtitle has-text-white">
        Select a range and what data type to get for all markets
      </h2>
      <div className="control">
        <div className="select mr-1">
          <select value={range} onChange={onSelectRange}>
            {rangeList.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
        <div className="select ml-1">
          <select value={dataType} onChange={onSelectOption}>
            {dataOptions.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </section>
  );
};
