import { markets } from '../data/market-data';
import { ranges } from '../data/range-data';
import {
  DEXFSalesPriceTypeCode,
  Market,
  MarketData,
  ProductDescription,
  SalesPrice,
} from './types';

const DEXF_API_KEY = '5bf70f96-11aa-4773-a4dd-f67330b651ee';

const getLocale = (country: string) => {
  const market = markets.find((market) => market.country === country);
  return `${market?.languages[0]}-${country?.toUpperCase()}`;
};

export const fetchDexfDataForMarket = async (range: string, country: string) => {
  const appId = ranges.find((r) => r.name === range)?.appId;
  const url = `https://api.dexf.ikea.com/webplanner/v1/query/items/retailunit/${country.toUpperCase()}/locale/${getLocale(
    country
  )}?fields=priceInformation&filter.appId=${appId}`;
  const header = {
    'DEXF-API-KEY': DEXF_API_KEY,
  };
  return fetch(url, {
    headers: header,
  }).then((response) => response.json());
};

export const fetchDexfDataForArticleAndMarket = async (country: string, artNbr: string) => {
  const url = `https://api.dexf.ikea.com/webplanner/v1/query/items/retailunit/${country.toUpperCase()}/locale/${getLocale(
    country
  )}?fields=*&filter.itemId=${artNbr}`;
  const header = {
    'DEXF-API-KEY': DEXF_API_KEY,
  };
  return fetch(url, {
    headers: header,
  }).then((response) => response.json());
};

const fetchProductData = async (products: string[]) => {
  const path = `https://api.dexf.ikea.com/product/v1/query?filter.itemId=${products.toString()}&fields=measureReference`;
  const header = {
    'DEXF-API-KEY': DEXF_API_KEY,
  };

  return fetch(path, {
    headers: header,
  }).then((response) => response.json());
};

export const fetchAllMarketsDexfDataForRange = async (range: string): Promise<MarketData[]> => {
  return await Promise.all(
    markets.map((market: Market) =>
      fetchDexfDataForMarket(range, market.country).then((result) => {
        return { market: market.country, data: result.data };
      })
    )
  );
};

export const fetchAllMarketsDexfDataForProduct = async (
  productNbr: string
): Promise<MarketData[]> => {
  return await Promise.all(
    markets.map((market: Market) =>
      fetchDexfDataForArticleAndMarket(market.country, productNbr).then((result) => {
        return { market: market.country, data: result.data };
      })
    )
  );
};

export const fetchProductDescriptions = async (products: any[]): Promise<ProductDescription[]> => {
  const splitIntoChunks = (arr: string[], chunkSize: number) => {
    const R: string[][] = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  };
  const artNbrs = products.map((p) => p.itemId);
  const productChunks = splitIntoChunks(artNbrs, 40);
  const result = await Promise.all(
    productChunks.flatMap(async (productChunk) => {
      const response: any = await fetchProductData(productChunk);
      const descriptionData = response.data.map((product: any) => {
        const name = product.content?.name || '';
        const typeName = product.content?.typeName || '';
        const designText = product.content?.validDesignText || '';
        const measurement = product.content?.measureReference
          ? product.content.measureReference.textMetric
          : '';
        const itemName = `${name} ${typeName} ${designText} ${measurement}`;
        return { artNr: product.itemId, description: itemName };
      });
      return descriptionData;
    })
  );
  return result.flat();
};

export const getPriceInfo = (priceData: SalesPrice[]) => {
  const familyPrice = priceData.find(
    (data) => data.typeCode === DEXFSalesPriceTypeCode.FAMILY_PRICE
  );
  if (familyPrice) {
    return `${familyPrice.priceInclTax} ${familyPrice.currencyCode} (FAM)`;
  }
  const regularPrice = priceData.find(
    (data) => data.typeCode === DEXFSalesPriceTypeCode.REGULAR_PRICE
  );
  const changeReason =
    regularPrice?.changeReason?.toLowerCase() === 'newlowerprice'
      ? '(NLP)'
      : regularPrice?.changeReason?.toLowerCase() === 'timerestrictedoffer'
      ? '(TRO)'
      : '';
  return regularPrice
    ? `${regularPrice.priceInclTax} ${regularPrice.currencyCode} ${changeReason}`
    : '';
};
