import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import { fetchDexfDataForArticleAndMarket } from '../utils/dexf';

const Modal = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
`;

const ModalContent = styled.div`
  margin: 10px;
`;

type Props = {
  artNbr: string;
  market: string;
  onClose: () => void;
};

export const ProductInfo = ({ market, artNbr, onClose }: Props) => {
  const [productInfo, setProductInfo] = useState<string | null>(null);

  useEffect(() => {
    fetchDexfDataForArticleAndMarket(market, artNbr).then((result) => setProductInfo(result));
  }, [artNbr, market]);

  return (
    <Modal>
      <button className="button is-info" onClick={() => onClose()}>
        <span className="icon">
          <i className="fas fa-arrow-left"></i>
        </span>
        <span>Back</span>
      </button>
      <ModalContent>
        {productInfo && (
          <ReactJson
            displayObjectSize={false}
            displayDataTypes={false}
            enableClipboard={false}
            src={JSON.parse(JSON.stringify(productInfo)).data[0]}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
