export const ranges: { name: string; appId: string }[] = [
  { name: 'PAX', appId: 'storageonepax' },
  { name: 'BESTÅ', appId: 'storageonebesta' },
  { name: 'PLATSA', appId: 'storageoneplatsa' },
  { name: 'EKET', appId: 'storageoneeket' },
  { name: 'SMÅSTAD', appId: 'storageonesmastad' },
  { name: 'BILLY', appId: 'storageonebilly' },
  { name: 'IVAR', appId: 'storageoneivar' },
  { name: 'BROR', appId: 'storageonebror' },
  { name: 'KALLAX', appId: 'storageonekallax' },
];
