import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useState } from 'react';
import { markets } from '../data/market-data';
import {
  fetchAllMarketsDexfDataForProduct,
  fetchAllMarketsDexfDataForRange,
  fetchProductDescriptions,
  getPriceInfo,
} from '../utils/dexf';
import { DEXFDataType, DEXFProductData, MarketData, ProductDescription } from '../utils/types';

const getDataForType = (productData: DEXFProductData, dataType: DEXFDataType) => {
  if (!productData) return '';
  if (dataType === DEXFDataType.PRICE) {
    return productData.valid && productData?.content?.priceInformation?.salesPrice
      ? getPriceInfo(productData.content.priceInformation.salesPrice)
      : '';
  } else if (dataType === DEXFDataType.LOCAL_ART_NBR) {
    return productData?.content?.ruItemNo || '';
  } else if (dataType === DEXFDataType.SALE_START_DATE) {
    return productData?.content?.saleStartDateText || '';
  } else if (dataType === DEXFDataType.SALE_END_DATE) {
    return productData?.content?.saleEndDateText || '';
  }
};

const generateRowData = (
  descriptionData: ProductDescription[],
  marketData: MarketData[],
  dataType: DEXFDataType
) => {
  const rowData = descriptionData.map((description, index) => {
    const artNbr = description.artNr;
    const descriptionText = description.description;
    const rowData = marketData.map((market) => {
      const productData = market.data[index];
      const marketName = market.market;
      const rowInfo = getDataForType(productData, dataType);
      return [marketName, rowInfo];
    });
    return {
      artNbr,
      desc: descriptionText,
      ...Object.fromEntries(rowData),
    };
  });
  return rowData;
};

type Props = {
  range?: string;
  productNumber?: string;
  dataType: DEXFDataType;
  onDoneLoading: () => void;
  onProductSelected: (market: string, artNbr: string) => void;
};

export const MarketTable = ({
  range,
  productNumber,
  dataType,
  onDoneLoading,
  onProductSelected,
}: Props) => {
  const [rowData, setRowData] = useState<any[]>([]);

  const getMarketData = useCallback(async () => {
    setRowData([]);
    const marketData = range
      ? await fetchAllMarketsDexfDataForRange(range)
      : productNumber
      ? await fetchAllMarketsDexfDataForProduct(productNumber)
      : undefined;
    if (!marketData) return;
    const description = await fetchProductDescriptions(marketData[0].data);
    setRowData(generateRowData(description, marketData, dataType));
    onDoneLoading();
  }, [dataType, onDoneLoading, productNumber, range]);

  useEffect(() => {
    getMarketData();
  }, [getMarketData]);

  const onCellClicked = useCallback(
    async (e: any) => {
      if (e.colDef.field === 'artNbr' || e.colDef.field === 'desc') {
        return;
      }
      onProductSelected(e.colDef.field, e.data.artNbr);
    },
    [onProductSelected]
  );

  const [columnDefs] = useState([
    {
      field: 'artNbr',
      headerName: 'Article Nbr',
      pinned: 'left',
      cellStyle: { textAlign: 'left' },
      sortable: true,
      filter: true,
    },
    {
      field: 'desc',
      headerName: 'Description',
      pinned: 'left',
      cellStyle: { textAlign: 'left' },
      resizable: true,
      sortable: true,
      filter: true,
    },
    ...markets.map((market) => ({
      field: market.country,
      headerName: market.name,
      cellStyle: { textAlign: 'left' },
      filter: true,
    })),
  ] as any[]);

  return (
    <>
      {rowData.length > 0 && (
        <div className="ag-theme-alpine" style={{ height: '85vh', width: 'auto' }}>
          <AgGridReact
            onCellClicked={onCellClicked}
            animateRows={true}
            rowData={rowData}
            columnDefs={columnDefs}
            alwaysShowHorizontalScroll={true}
          ></AgGridReact>
        </div>
      )}
    </>
  );
};
