export interface DEXFReason {
  itemId: string;
  code: string;
  message: string;
  detail: string;
}

export type ImageSize = 'S1' | 'S2' | 'S3' | 'S4' | 'S5';

export interface DEXFImage {
  sortNo: string;
  typeName: string;
  size: ImageSize;
  url: string;
}

export interface DEXFMeasure {
  typeCode: DEXFMeasureType;
  value: number;
  textMetric: string;
  textImperial: string;
}

export enum DEXFMeasureType {
  FRAME_WIDTH = '00116',
  FRAME_WIDTH_MESH_BACKET = '00322',
  FRAME_DEPTH = '00117',
  FRAME_HEIGHT = '00118',
  THICKNESS = '00035',
  HEIGHT = '00041',
  WIDTH = '00047',
  DEPTH = '00044',
}

export enum DEXFColor {
  BROWN = '10019',
  BLACK = '10139',
  WHITE = '10156',
  BEIGE = '10003',
  GREY = '10028',
  YELLOW = '10042',
  BLUE = '10007',
  RED = '10124',
  GREEN = '10033',
  TURQUOISE = '10152',
  ORANGE = '10112',
  PINK = '10119',
  MULTICOLOR = '10583',
}

export enum DEXFProductGoodToKnowTypeName {
  WARNING = 'Warning',
  COMPL_ASSEMBLY_INFORMATION = 'Compl. assembly information',
  SOLD_SEPARATELY = 'Sold Separately',
}

export interface DEXFFilter {
  headerCode: string;
  headerText: string;
  valueCode: string;
  value: string;
}

export enum DEXFPriceUnitTypeCode {
  packageQuantity = '00061',
}

export enum DEXFSalesPriceTypeCode {
  REGULAR_PRICE = 'RegularSalesUnitPrice',
  FAMILY_PRICE = 'IKEAFamilySalesUnitPrice',
}

export interface DEXFPriceUnit {
  typeCode: DEXFPriceUnitTypeCode;
  value: number;
}

export type DEXFProductGoodToKnow = {
  typeName: DEXFProductGoodToKnowTypeName;
  text: string;
};
export type SalesPrice = {
  currencyCode: string;
  indicativeTotalTax: number;
  priceExclTax: number;
  priceInclTax: number;
  typeCode: DEXFSalesPriceTypeCode;
  changeReason?: 'TimeRestrictedOffer' | 'Temporary';
  previousSalesPrice?: { priceInclTax: number; priceExclTax: number };
  validFromDateTime?: string;
  validFromText?: string;
  validToDateTime?: string;
  validToText?: string;
};

export interface DEXFArticle {
  itemId: string;
  itemType: string;
  itemNoGlobal: string;
  ruItemNo: string;
  typeName: string;
  typeCode: string;
  valid: boolean;
  reason: DEXFReason[];
  image: DEXFImage[];
  measure: DEXFMeasure[];
  goodToKnow: DEXFProductGoodToKnow[];
  saleStartDateText: string;
  saleEndDateText: string;
  measureReference: {
    textMetric: string;
    textImperial: string;
  };
  priceInformation: {
    homeDeliveryOnly: boolean;
    onlineSaleable: boolean;
    salesPrice: SalesPrice[];
  };
  priceUnit?: DEXFPriceUnit[];
  validDesignText: string;
  filterAttribute: DEXFFilter[];
  customerBenefitSummary: {
    text: string;
  };
}

export type DEXFProductData = { valid: boolean; reason?: DEXFReason[]; content?: DEXFArticle };

export enum DEXFDataType {
  PRICE = 'Price',
  LOCAL_ART_NBR = 'Local Item Number',
  SALE_START_DATE = 'Sale Start Date',
  SALE_END_DATE = 'Sale End Date',
}

export type MarketData = { market: string; data: DEXFProductData[] };

export type ProductDescription = { artNr: string; description: string };

export type Market = { name: string; country: string; languages: string[] };
