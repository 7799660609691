import React, { useState } from 'react';

type Props = {
  onClose: () => void;
  onOk: (number: string) => void;
};

export const InputModal = ({ onOk, onClose }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const onOkClicked = () => {
    if (inputValue.length === 0) {
      onClose();
    } else if (inputValue.startsWith('ART-') || inputValue.startsWith('SPR-')) {
      onOk(inputValue.replace('.', ''));
    } else if (inputValue.toUpperCase().startsWith('S')) {
      onOk(inputValue.toUpperCase().replace('S', 'SPR-').replace('.', ''));
    } else if (inputValue[1] === '9') {
      onOk(`SPR-${inputValue.replace('.', '')}`);
    } else {
      onOk(`ART-${inputValue.replace('.', '')}`);
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content">
        <div className="box">
          <h4 className="title is-size-4 has-text-weight-medium">Input ART/SPR number</h4>
          <div className="field mb-6">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="ART/SPR number"
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                value={inputValue}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-primary is-info" onClick={onOkClicked}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={onClose} />
    </div>
  );
};
