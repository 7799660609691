import React, { useState } from 'react';
import { ClockLoader } from 'react-spinners';
import './App.css';
import { Header } from './components/Header';
import styled from 'styled-components';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import { ProductInfo } from './components/ProductInfo';
import { MarketTable } from './components/MarketTable';
import { DEXFDataType } from './utils/types';
import { ranges } from './data/range-data';
import { InputModal } from './components/InputModal';

const Spinner = styled(ClockLoader)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20rem;
`;

function App() {
  const [range, setRange] = useState<string>('');
  const [productNumber, setProductNumber] = useState<string>('');
  const [dataType, setDataType] = useState<DEXFDataType>(DEXFDataType.PRICE);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showInputModal, setShowInputModal] = useState<boolean>(false);
  const [productInfo, setProductInfo] = useState<{ market: string; artNbr: string } | null>(null);

  const onRangeSelected = async (range: string) => {
    if (ranges.find((r: any) => r.name === range)) {
      setRange(range);
      setProductInfo(null);
      setShowSpinner(true);
    } else {
      setShowInputModal(true);
    }
  };

  const onDataTypeSelected = async (dataType: DEXFDataType) => {
    setDataType(dataType);
  };

  const onProductSelected = (market: string, artNbr: string) => {
    setProductInfo({ market, artNbr });
  };

  const onCloseInputModal = () => {
    setShowInputModal(false);
  };

  const onOkInputModal = (productNbr: string) => {
    setShowInputModal(false);
    setRange('');
    setProductNumber(productNbr);
    setProductInfo(null);
    setShowSpinner(true);
  };

  return (
    <div className="App">
      <Header
        range={range}
        dataType={dataType}
        onRangeSelected={onRangeSelected}
        onDataTypeSelected={onDataTypeSelected}
      />
      {showInputModal && <InputModal onClose={onCloseInputModal} onOk={onOkInputModal} />}
      {showSpinner && <Spinner color={'#3298dc'} size={100} />}
      {!productInfo && (
        <MarketTable
          range={range}
          productNumber={productNumber}
          dataType={dataType}
          onDoneLoading={() => setShowSpinner(false)}
          onProductSelected={onProductSelected}
        />
      )}
      {productInfo && (
        <ProductInfo
          artNbr={productInfo.artNbr}
          market={productInfo.market}
          onClose={() => setProductInfo(null)}
        />
      )}
    </div>
  );
}

export default App;
