import { Market } from '../utils/types';

export const markets: Market[] = [
  {
    country: 'au',
    languages: ['en'],
    name: '🇦🇺 Australia',
  },
  {
    country: 'at',
    languages: ['de'],
    name: '🇦🇹 Austria',
  },
  {
    country: 'bh',
    languages: ['ar', 'en'],
    name: '🇧🇭 Bahrain',
  },
  {
    country: 'be',
    languages: ['fr', 'nl', 'en'],
    name: '🇧🇪 Belgium',
  },
  {
    country: 'bg',
    languages: ['bg'],
    name: '🇧🇬 Bulgaria',
  },
  {
    country: 'ca',
    languages: ['en', 'fr'],
    name: '🇨🇦 Canada',
  },
  {
    country: 'cl',
    languages: ['es'],
    name: '🇨🇱 Chile',
  },
  {
    country: 'cn',
    languages: ['en', 'zh'],
    name: '🇨🇳 China',
  },
  {
    country: 'co',
    languages: ['es'],
    name: '🇨🇴 Colombia',
  },
  {
    country: 'hr',
    languages: ['hr'],
    name: '🇭🇷 Croatia',
  },
  {
    country: 'cy',
    languages: ['el'],
    name: '🇨🇾 Cyprus',
  },
  {
    country: 'cz',
    languages: ['cs'],
    name: '🇨🇿 Czechia',
  },
  {
    country: 'dk',
    languages: ['da'],
    name: '🇩🇰 Denmark',
  },
  {
    country: 'do',
    languages: ['es'],
    name: '🇩🇴 Dominican Republic',
  },
  {
    country: 'eg',
    languages: ['en', 'ar'],
    name: '🇪🇬 Egypt',
  },
  {
    country: 'ee',
    languages: ['et', 'en', 'ru'],
    name: '🇪🇪 Estonia',
  },
  {
    country: 'fi',
    languages: ['fi'],
    name: '🇫🇮 Finland',
  },
  {
    country: 'fr',
    languages: ['fr'],
    name: '🇫🇷 France',
  },
  {
    country: 'de',
    languages: ['de'],
    name: '🇩🇪 Germany',
  },
  {
    country: 'gb',
    languages: ['en'],
    name: '🇬🇧 Great Britain',
  },
  {
    country: 'gr',
    languages: ['el'],
    name: '🇬🇷 Greece',
  },
  {
    country: 'hk',
    languages: ['en', 'zh'],
    name: '🇭🇰 Hong Kong',
  },
  {
    country: 'hu',
    languages: ['hu'],
    name: '🇭🇺 Hungary',
  },
  {
    country: 'is',
    languages: ['is'],
    name: '🇮🇸 Iceland',
  },
  {
    country: 'in',
    languages: ['en'],
    name: '🇮🇳 India',
  },
  {
    country: 'id',
    languages: ['in', 'en'],
    name: '🇮🇩 Indonesia',
  },
  {
    country: 'ie',
    languages: ['en'],
    name: '🇮🇪 Ireland',
  },
  {
    country: 'il',
    languages: ['en', 'iw'],
    name: '🇮🇱 Israel',
  },
  {
    country: 'it',
    languages: ['it'],
    name: '🇮🇹 Italy',
  },
  {
    country: 'jp',
    languages: ['en', 'ja'],
    name: '🇯🇵 Japan',
  },
  {
    country: 'jo',
    languages: ['en', 'ar'],
    name: '🇯🇴 Jordan',
  },
  {
    country: 'kw',
    languages: ['en', 'ar'],
    name: '🇰🇼 Kuwait',
  },
  {
    country: 'lv',
    languages: ['lv', 'ru', 'en'],
    name: '🇱🇻 Latvia',
  },
  {
    country: 'lt',
    languages: ['lt'],
    name: '🇱🇹 Lithuania',
  },
  {
    country: 'my',
    languages: ['en', 'ms'],
    name: '🇲🇾 Malaysia',
  },
  {
    country: 'mx',
    languages: ['en', 'es'],
    name: '🇲🇽 Mexico',
  },
  {
    country: 'ma',
    languages: ['en', 'ar', 'fr'],
    name: '🇲🇦 Morocco',
  },
  {
    country: 'nl',
    languages: ['nl'],
    name: '🇳🇱 Netherlands',
  },
  {
    country: 'no',
    languages: ['no'],
    name: '🇳🇴 Norway',
  },
  {
    country: 'om',
    languages: ['ar', 'en'],
    name: '🇴🇲 Oman',
  },
  {
    country: 'ph',
    languages: ['en'],
    name: '🇵🇭 Philippines',
  },
  {
    country: 'pl',
    languages: ['pl'],
    name: '🇵🇱 Poland',
  },
  {
    country: 'pr',
    languages: ['es', 'en'],
    name: '🇵🇷 Puerto Rico',
  },
  {
    country: 'pt',
    languages: ['pt', 'en'],
    name: '🇵🇹 Portugal',
  },
  {
    country: 'qa',
    languages: ['en', 'ar'],
    name: '🇶🇦 Qatar',
  },
  {
    country: 'ro',
    languages: ['ro'],
    name: '🇷🇴 Romania',
  },
  {
    country: 'ru',
    languages: ['ru'],
    name: '🇷🇺 Russia',
  },
  {
    country: 'sa',
    languages: ['ar', 'en'],
    name: '🇸🇦 Saudi Arabia',
  },
  {
    country: 'rs',
    languages: ['sr'],
    name: '🇷🇸 Serbia',
  },
  {
    country: 'sg',
    languages: ['en'],
    name: '🇸🇬 Singapore',
  },
  {
    country: 'sk',
    languages: ['sk'],
    name: '🇸🇰 Slovakia',
  },
  {
    country: 'kr',
    languages: ['ko', 'en'],
    name: '🇰🇷 South Korea',
  },
  {
    country: 'es',
    languages: ['es', 'eu', 'en', 'ca'],
    name: '🇪🇸 Spain',
  },
  {
    country: 'sp',
    languages: ['es', 'en'],
    name: '🇪🇸 Spanish Islands Baleares',
  },
  {
    country: 'ce',
    languages: ['es', 'en'],
    name: '🇪🇸 Spanish Islands Canarias',
  },
  {
    country: 'se',
    languages: ['sv'],
    name: '🇸🇪 Sweden',
  },
  {
    country: 'ch',
    languages: ['fr', 'de', 'en'],
    name: '🇨🇭 Switzerland',
  },
  {
    country: 'tw',
    languages: ['zh'],
    name: '🇹🇼 Taiwan',
  },
  {
    country: 'th',
    languages: ['en', 'th'],
    name: '🇹🇭 Thailand',
  },
  {
    country: 'tr',
    languages: ['tr'],
    name: '🇹🇷 Turkey',
  },
  {
    country: 'ae',
    languages: ['en', 'ar'],
    name: '🇦🇪 United Arab Emirates',
  },
  {
    country: 'ua',
    languages: ['uk'],
    name: '🇺🇦 Ukraine',
  },
  {
    country: 'us',
    languages: ['en'],
    name: '🇺🇸 United States',
  },
];
